import { GetterTree } from 'vuex';
import { IInventoryState, IWallet, IFiatBalances, IClaimFee } from './types';
import { IRootState } from '../types';

export const getters: GetterTree<IInventoryState, IRootState> = {
  gyriTollBalance(state: IInventoryState): number {
    const tollWallet = state.wallets.find((wallet: IWallet) => {
      return wallet.symbol === 'GALA[GYRI]';
    });

    return Number(tollWallet?.balance?.confirmed ?? 0);
  },

  galaBalance(state: IInventoryState): number {
    const galaWallet = state.wallets.find((wallet: IWallet) => {
      return wallet.symbol === 'GALA[ETH]';
    });

    if (
      galaWallet &&
      galaWallet.balance &&
      galaWallet.balance.confirmed &&
      galaWallet.balance.unconfirmed
    ) {
      const { unconfirmed, confirmed } = galaWallet.balance;
      const lesserBalance = Math.min(+confirmed, +unconfirmed);

      return lesserBalance;
    }

    return 0;
  },

  getFiatBalances(state: IInventoryState): IFiatBalances {
    const { wallets } = state;
    const fiatBalances: IFiatBalances = { total: 0 };

    for (const wallet of wallets) {
      const { symbol, balance, receiveAddress, coinPrice } = wallet;
      let usdVal = 0;

      if (receiveAddress) {
        const lesserBalance = Math.min(
          +balance.confirmed,
          +balance.unconfirmed,
        );

        usdVal = coinPrice * lesserBalance;
      }

      fiatBalances[symbol] = usdVal;
      fiatBalances.total += usdVal;
    }

    return fiatBalances;
  },

  userItemsCount(state: IInventoryState) {
    const count = state.userItems.reduce((total, collection) => {
      return total + collection.quantity;
    }, 0);

    return count;
  },

  tokenClaimFeeId(state: IInventoryState) {
    const claimFee = state.claimFees.find(
      fee => fee.network === state.claimNetwork.network,
    ) as IClaimFee;

    return claimFee.id;
  },

  ethWalletAddress(state: IInventoryState) {
    const [ethWallet] =
      state.wallets.filter(wallet => wallet.symbol.toUpperCase() === 'ETH') ??
      [];

    if (!ethWallet) {
      return '';
    }

    return ethWallet.receiveAddress;
  },

  getVestingSchedule(state: IInventoryState) {
    return (tokenId: string) => {
      const schedule = state.vestingScheduleByTokenId?.[tokenId] || [];
      return schedule;
    };
  },

  getTokenRedistributionPoolAmount(state: IInventoryState) {
    return (tokenId: string) => {
      const tokenRedistributionPoolAmount =
        state.tokenRedistributionPoolAmount?.[tokenId];
      return tokenRedistributionPoolAmount ?? 0;
    };
  },
};
