



















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { reverse } from 'lodash';
import { IWallet } from '~/store/inventory/types';
import ReceiveFunds from '~/components/Wallet/ReceiveFunds.vue';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import { TextOutput } from '~/mixins';

@Component({
  components: { ReceiveFunds, FullScreenDialog },
})
export default class InsufficientFundsList extends Mixins(TextOutput) {
  @Prop(Array) currencies!: Array<{ wallet: IWallet; isFee: boolean }>;
  @Prop(Boolean) bottomDrawer!: boolean;

  showInsufficientFunds = true;
  purchaseFundsLinks: {
    [key: string]: { link?: string; route?: string; cta: any };
  } = {
    trez: {
      // TODO: change this link to the correct one when we have it
      link: 'https://app.uniswap.org/explore/tokens/ethereum',
      cta: this.$t('components.wallet.insufficientFundsList.buyTrez'),
    },
    gala: {
      route:
        '/games/buy-item/GalaGamesBadgesTraderProfileBadgeUncommon?currency=CC-COINFLOW',
      cta: this.$t('components.wallet.insufficientFundsList.buyStarterPack'),
    },
  };

  get currenciesToShow() {
    return this.bottomDrawer
      ? this.currencies.slice(-1)
      : reverse(this.currencies);
  }

  filterOutNetwork(symbol: string) {
    return symbol.replace(/\[.*\]/, '');
  }

  goTo(route: string) {
    this.$router.push(route);
    this.$emit('close');
  }

  close() {
    this.showInsufficientFunds = false;
  }
}
