










































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import escapeHtml from 'escape-html';
import {
  subDays,
  subMinutes,
  isBefore,
  startOfDay,
  differenceInDays,
} from 'date-fns';
import FeeVestingGraph from '~/components/Wallet/FeeVesting/FeeVestingGraph.vue';
import { getPercFormat } from '~/utils/format';
import FeeVestingRedistributionCard from '~/components/Wallet/FeeVesting/FeeVestingRedistribution.vue';
import { isTodayUTC } from '~/helpers/date-helpers';

@Component({
  components: {
    FeeVestingGraph,
    FeeVestingRedistributionCard,
  },
})
export default class FeeVestingGraphSection extends Vue {
  @Prop({ type: Array, default: [] }) readonly rawData!: Array<{
    date: string;
    burnToMintRatio: number;
  }>;
  @Prop({ type: String, default: '' }) readonly sectionContainer!: string;
  @Prop({ type: String, default: '' }) readonly chartContainer!: string;
  @Prop(String) readonly symbol!: string;
  @Prop(String) readonly icon!: string;
  @Prop(String) readonly tokenColor!: string;
  @Prop(Number) readonly redistributionAmount!: number;

  @Action('getFeeVestingSchedule', { namespace: 'inventory' })
  private getFeeVestingSchedule!: (symbol: string) => void;

  get colorString() {
    return `rgb(${this.tokenColor})`;
  }

  get descriptionHtmlString() {
    return this.$t(
      'components.wallet.feeVesting.feeVestingGraphSection.vestingDesc',
      {
        symbol: escapeHtml(this.symbol),
        color: escapeHtml(this.colorString),
        vestPeriod: escapeHtml(this.vestingDaysRemaining.toString()),
        minFee: escapeHtml(this.minFee),
        currFee: escapeHtml(this.currFee),
      },
    );
  }

  get yesterday() {
    const date = subDays(new Date(), 1);
    date.setUTCHours(0, 0, 0, 0);
    return date;
  }

  get vestingData() {
    const upcomingDaysAndYesterday = this.rawData.filter(
      (d: { date: string; burnToMintRatio: number }) =>
        isBefore(subMinutes(this.yesterday, 1), new Date(d.date)),
    );
    const last20 = this.rawData.slice(-20);
    // NOTE: try to have at least 20 days of data so chart looks good
    return last20.length > upcomingDaysAndYesterday.length
      ? last20
      : upcomingDaysAndYesterday;
  }

  get showGraph() {
    // NOTE: only show if there is data and the last date in data
    // is more than yesterday (1 day offset to be safe from timezone issues)
    return (
      this.vestingData?.length > 0 &&
      isBefore(
        this.yesterday,
        new Date(this.vestingData?.[this.vestingData.length - 1].date),
      )
    );
  }

  get vestingDaysRemaining() {
    const endDate = this.vestingData[this.vestingData.length - 1]?.date;
    return endDate ? differenceInDays(new Date(endDate), new Date()) : 0;
  }

  get currFee() {
    const fee =
      this.vestingData?.find(d => isTodayUTC(new Date(d.date)))
        ?.burnToMintRatio || 0.75;
    return getPercFormat(fee * 100, 0, false);
  }

  get minFee() {
    const fee =
      +this.vestingData?.[this.vestingData?.length]?.burnToMintRatio || 0.05;

    return getPercFormat(fee * 100, 0, false);
  }

  created() {
    this.getFeeVestingSchedule(`${this.symbol}[GalaChainAllowance]`);
  }
}
