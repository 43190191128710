export function formatNumberWithCommas(
  num: number | null | undefined,
  decimals = 2,
) {
  return (num || 0).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function getPercFormat(value: number, decimals = 2, includeSign = true) {
  const sign = includeSign ? (value >= 0 ? '+' : '-') : '';
  return sign + formatNumberWithCommas(value || 0, decimals) + '%';
}
