




















































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Action, Mutation, State, Getter } from 'vuex-class';
import { debounce } from 'lodash';
import _omit from 'lodash.omit';
import { BigNumber } from 'bignumber.js';
import {
  IClaimDetail,
  ITokenClaimPaymentData,
  IWallet,
  ITokenFeeVestingItem,
} from '~/store/inventory/types';
import { getChainInfo } from '~/data/chain_info';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import InsufficientFundsList from '~/components/Wallet/InsufficientFundsList.vue';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import GasFees from '~/components/Wallet/GasFees.vue';
import PostMintLockExplanation from '~/components/Wallet/PostMintLockExplanation.vue';
import BalanceCard from '~/components/Wallet/BalanceCard.vue';
import FeeVestingDisclaimer from '~/components/Wallet/FeeVesting/FeeVestingDisclaimer.vue';
import FeeVestingGraphSection from '~/components/Wallet/FeeVesting/FeeVestingGraphSection.vue';
import PlayOnTelegram from '~/components/Games/PlayOnTelegram.vue';
import AuthQuery from '~/mixins/AuthQuery';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import { GalaChainMethods } from '~/types/gala-chain';
import TransactionFees from '~/mixins/TransactionFees';
import ExternalWallet from '~/mixins/ExternalWallet';
import TextOutput from '~/mixins/TextOutput';
import CopyToClipboard from '~/mixins/CopyToClipboard';
import { checkForSufficientFunds } from '~/utils/checkForSufficientFunds';
import { tokenColors } from '~/data/tokenAssociatedColors';
import redeemCasinoCreditsMutation from '~/mutations/redeemCasinoCredits.gql';
import casinoCreditsConversionRateQuery from '~/queries/casinoCreditsConversionRate.gql';

@Component({
  components: {
    TwoFactorAuthInputPrompt,
    InsufficientFundsList,
    GasFees,
    RequirePasscode,
    PostMintLockExplanation,
    BalanceCard,
    PlayOnTelegram,
    FeeVestingGraphSection,
    FeeVestingDisclaimer,
  },
})
export default class ConvertGalaChainCurrency extends Mixins(
  AuthQuery,
  ExternalWallet,
  TextOutput,
  TransactionFees,
  CopyToClipboard,
) {
  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @Prop(Object) readonly allowanceWallet!: IWallet & {
    address: string;
    claimDetails: IClaimDetail[];
  };
  @Prop(Number) readonly availableBalance!: number;
  @Prop({ type: String, default: 'mint' }) readonly action!:
    | 'redeem_casino_credits'
    | 'mint';

  @Getter('getVestingSchedule', { namespace: 'inventory' })
  getVestingSchedule!: (tokenId: string) => ITokenFeeVestingItem[];
  @Getter('getTokenRedistributionPoolAmount', { namespace: 'inventory' })
  getTokenRedistributionPoolAmount!: (tokenId: string) => number;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  @Action('getFeeVestingSchedule', { namespace: 'inventory' })
  private getFeeVestingSchedule!: (symbol: string) => void;

  @Action('claimTokens', { namespace: 'inventory' })
  private claimTokens!: (payload: {
    walletPassword?: string;
    selectedItems: any[];
    gasCost: number;
    transactionFeePrice: string;
    totpToken: string;
  }) => Promise<{
    data: {
      [key: string]: {
        success: boolean;
        message: string;
        paymentData: ITokenClaimPaymentData;
      };
    };
  }>;

  successAmount: number = 0;
  successCasinoCreditAmount: number = 0;
  userSpecifiedAmount: number | null = null;
  requestInFlight = false;
  requirePasscode = false;
  showFeeVestingSchedule = false;
  success = false;
  casinoCreditCouponCode = '';
  casinoCreditConversionRate: number | null = null;
  trezValueUsd: number | null = null;

  debounceHandler = debounce((cb: Function) => {
    cb();
  }, 600);

  /////// Computed properties ////////
  get actionButtonDisabled() {
    return !!(
      +this.amount > this.availableBalance ||
      this.isFetchingEstimatedFee ||
      this.insufficientFundsWallets.length ||
      !(+this.amount > 0)
    );
  }

  get amount() {
    return this.userSpecifiedAmount ?? this.availableBalance;
  }

  get casinoCreditAmount() {
    if (this.trezValueUsd && this.casinoCreditConversionRate) {
      const usdValueOfTokensToConvert = BigNumber(this.amount).times(
        this.trezValueUsd,
      );
      return +usdValueOfTokensToConvert
        .times(this.casinoCreditConversionRate)
        .toNumber()
        .toFixed(2);
    }
    return 0;
  }

  get convertHeaderText() {
    const convertFrom = this.filterAllSymbolsText(this.allowanceWallet.symbol)
      .replace(/\[\w*\]/, '')
      .replace(/^\$/, '');
    const convertTo = this.filterAllSymbolsText(
      this.targetWallet?.symbol?.replace(/^\$/, '') ?? '',
    );
    return this.action === 'redeem_casino_credits'
      ? this.$t(
          'components.wallet.convertGalaChainCurrency.redeemCasinoCreditsHeader',
          {
            convertFrom,
            convertTo,
          },
        )
      : this.$t('components.wallet.convertGalaChainCurrency.convertHeader', {
          convertFrom,
          convertTo,
        });
  }

  get convertToSymbol() {
    const symbol = this.allowanceWallet.symbol;
    const regEx = /\[\w*\]/;
    if (regEx.test(symbol)) {
      return symbol.replace(regEx, '[GYRI]');
    }
    return symbol;
  }

  get feesWithExplanation() {
    const burnToMintPercentage =
      this.mintConfig?.postMintBurn?.burnPercentage ??
      this.mintConfig?.preMintBurn?.burnPercentage;

    return this.transactionFeeWallets
      .map(fw => {
        const isGasFee = fw.currency?.includes('GALA');
        return {
          ...fw,
          feeName: isGasFee
            ? this.$t('components.wallet.gasFees.gasFee')
            : this.$t('components.wallet.gasFees.conversionFee'),
          tooltip: isGasFee
            ? this.$t('components.wallet.gasFees.gasFeeTooltip')
            : burnToMintPercentage
            ? this.$t('components.wallet.gasFees.conversionFeeTooltip', {
                percentage: burnToMintPercentage * 100,
              })
            : null,
        };
      })
      .filter(f => f.tooltip);
  }

  get hasGasFees() {
    return this.targetChainInformation?.hasSendGasFees ?? true;
  }

  get hasFeeVesting() {
    return !!this.allowanceWallet?.hasFeeVesting;
  }

  get insufficientFundsWallets() {
    return this.transactionFeeWallets
      .filter(w => {
        // @ts-ignore: w.wallet will never be undefined
        const { sufficient } = checkForSufficientFunds(+w.feeAmount, w.wallet);

        if (
          this.targetWallet?.symbol === w.wallet?.symbol &&
          this.mintConfig?.postMintBurn?.burnPercentage &&
          !this.mintConfig.preMintBurn?.burnPercentage
        ) {
          return false;
        }
        return !sufficient;
      })
      .map(w => ({ wallet: w.wallet, isFee: true }));
  }

  get mintConfig() {
    return this.targetWallet?.mintConfiguration;
  }

  get showFeeVestingDisclaimer() {
    return this.feeVestingSchedule.length && this.amount;
  }

  get successImage() {
    return this.action === 'redeem_casino_credits'
      ? 'https://static.gala.games/images/coupon-big.png'
      : this.targetWallet?.icon;
  }

  get symbolWithoutNetwork() {
    return this.filterAllSymbolsText(
      this.allowanceWallet.symbol.replace(/\[\w*\]/, '').replace(/^\$/, ''),
    );
  }

  get targetChainInformation() {
    return getChainInfo(this.targetWallet?.network || 'GYRI');
  }

  get targetWallet() {
    return this.wallets.find(w => w.symbol === this.convertToSymbol);
  }

  get tokenColor() {
    const symbol = this.allowanceWallet.symbol;
    if (symbol && tokenColors[symbol as keyof Object]) {
      return tokenColors[symbol as keyof Object];
    }

    return '';
  }

  get feeVestingSchedule() {
    return this.getVestingSchedule(this.allowanceWallet?.tokenId ?? '');
  }

  get tokenRedistributionPoolAmount() {
    return this.getTokenRedistributionPoolAmount(
      this.allowanceWallet?.tokenId ?? '',
    );
  }
  /////////////////////////////

  /////// Input field rules /////////
  requiredField(val: string) {
    if (!val) {
      return this.$t('common.notifications.requiredField');
    }

    return true;
  }

  positiveValue(val: string) {
    if (Number(val) <= 0) {
      return this.$t('components.wallet.convertCoin.positiveValue');
    }

    return true;
  }

  maxValue(val: string | number) {
    val = Number(val);
    const message = this.$t(
      'components.wallet.convertCoin.amountExceedsAvailableBalance',
      { symbol: this.filterAllSymbolsText(this.allowanceWallet.symbol) },
    );

    if (val > this.availableBalance) {
      return message;
    }

    return true;
  }
  ///////////////////////////

  created() {
    this.isFetchingTransferFee = true;
    this.getFeeVestingSchedule(this.allowanceWallet.symbol);
    this.checkUrlForInitialAmount();
  }

  amountChanged(newAmount: number) {
    this.userSpecifiedAmount = newAmount;
  }

  checkUrlForInitialAmount() {
    const urlAmount = this.$route.query.amount;
    if (urlAmount && +urlAmount <= this.availableBalance) {
      this.amountChanged(+urlAmount);
    }
  }

  handleSubmitClick() {
    if (!this.hasGyriPassphrase) {
      this.toggleCreateWalletPrompt({
        show: true,
        walletType: 'gyri',
      });
      return;
    }

    if (this.insufficientFundsWallets.length) {
      return;
    }

    this.requirePasscode = true;
  }

  async onTransactionActionComplete(args: {
    success: boolean;
    message: string;
    results: any;
  }) {
    this.successCasinoCreditAmount = this.casinoCreditAmount;
    this.successAmount = +this.amount;
    this.requirePasscode = false;
    this.success = true;
    this.$emit('success', true);
  }

  async onTransactionActionError(args: { message: string; error: Error }) {
    console.warn(args.error);
    this.updateSnackbarErrorText(args.message);
    this.toggleErrorSnackbar(true);
  }

  async convertAction(walletPassword: string) {
    return this.action === 'redeem_casino_credits'
      ? this.redeemCasinoCredits(walletPassword)
      : this.claimItems(walletPassword);
  }

  async redeemCasinoCredits(walletPassword: string) {
    const result = await this.doAuthQuery(async totpToken => {
      try {
        const apolloClient = this.$apolloProvider.defaultClient;

        const results = await apolloClient.mutate({
          mutation: redeemCasinoCreditsMutation,
          variables: {
            numTokens: +this.amount,
            numCredits: this.casinoCreditAmount,
            transferCode: walletPassword,
            totpToken,
          },
        });

        if (!results?.data.redeemCasinoCredits?.couponCode) {
          const message = this.$t('common.notifications.somethingWentWrong');
          this.updateSnackbarErrorText(message);
          this.toggleErrorSnackbar();
          const error = new Error(message as string);
          throw error;
        }
        this.casinoCreditCouponCode =
          results.data.redeemCasinoCredits.couponCode;
        return true;
      } catch (error) {
        this.$sentry.captureException(error);
        this.requestInFlight = false;
        throw error;
      }
    }, TwoFaCheckpoint.transactions);

    if (!result) {
      throw new Error('2FA canceled');
    }
  }

  async claimItems(walletPassword: string) {
    const galaFee = this.transactionFeeWallets.find(
      w => w.currency === 'GALA[GYRI]',
    );

    const result = await this.doAuthQuery(async totpToken => {
      try {
        const selectedItems =
          this.setClaimQuantities(this.allowanceWallet.claimDetails) || [];
        const results = await this.claimTokens({
          walletPassword,
          selectedItems,
          gasCost: this.tokenClaimFee,
          transactionFeePrice: (galaFee?.feeAmount ?? 0).toString(),
          totpToken,
        });

        if (!results?.data.claimTokensV2?.success) {
          const message =
            results?.data.claimTokensV2?.message ??
            this.$t('common.notifications.somethingWentWrong');
          this.updateSnackbarErrorText(message);
          this.toggleErrorSnackbar();
          const error = new Error(message);
          throw error;
        }

        return true;
      } catch (error) {
        this.$sentry.captureException(error);
        this.requestInFlight = false;
        throw error;
      }
    }, TwoFaCheckpoint.transactions);

    if (!result) {
      throw new Error('2FA canceled');
    }
  }

  setClaimQuantities(claimDetails: IClaimDetail[]) {
    let selectedQuantityRemaining = +this.amount;
    return claimDetails.map(claim => {
      const claimQuantity = +claim.quantity;
      if (selectedQuantityRemaining >= claimQuantity) {
        selectedQuantityRemaining -= claimQuantity;
        return { ...claim, tokenId: this.allowanceWallet.tokenId };
      } else {
        const updatedClaim = {
          ...claim,
          quantity: selectedQuantityRemaining,
          tokenId: this.allowanceWallet.tokenId,
        };
        selectedQuantityRemaining = 0;
        return updatedClaim;
      }
    });
  }

  async getCasinoCreditConversionRate() {
    try {
      const apolloClient = this.$apolloProvider.defaultClient;

      const results = await apolloClient.query({
        query: casinoCreditsConversionRateQuery,
        variables: {
          numTokens: +this.amount,
        },
      });

      if (!results?.data.casinoCreditConversionRate?.conversionRate) {
        const message = this.$t('common.notifications.somethingWentWrong');
        this.updateSnackbarErrorText(message);
        this.toggleErrorSnackbar();
        const error = new Error(message as string);
        throw error;
      }
      this.casinoCreditConversionRate =
        results.data.casinoCreditConversionRate.conversionRate;
      this.trezValueUsd = results.data.casinoCreditConversionRate.trezValueUsd;
    } catch (error) {
      this.$sentry.captureException(error);
      throw error;
    }
  }

  @Watch('amount', { immediate: true })
  onAmountChange(amount: string) {
    this.debounceHandler(() => {
      if (+amount > 0 && +this.amount <= this.availableBalance) {
        const symbolMatch = this.convertToSymbol.match(/^(\w*)\[\w*\]$/);
        const tokenClassKey = this.targetWallet?.tokenClassKey || {
          collection: symbolMatch ? symbolMatch[1] : '',
          category: 'Unit',
          type: 'none',
          additionalKey: 'none',
        };

        const transactionData = [
          {
            method: GalaChainMethods.MintToken,
            tokenInstances: [
              {
                quantity: this.amount.toString(),
                tokenInstanceKey: {
                  ..._omit(tokenClassKey, '__typename'),
                  instance: '0',
                },
              },
            ],
          },
        ];

        if (this.action === 'redeem_casino_credits') {
          if (!this.casinoCreditConversionRate || !this.trezValueUsd) {
            this.getCasinoCreditConversionRate();
          }

          transactionData.push({
            method: GalaChainMethods.BurnTokens,
            tokenInstances: [
              {
                quantity: this.amount.toString(),
                tokenInstanceKey: {
                  ..._omit(tokenClassKey, '__typename'),
                  instance: '0',
                },
              },
            ],
          });
        }

        this.getGalachainTransactionFeeEstimates(transactionData);
      } else {
        this.isFetchingTransferFee = false;
      }
    });
  }
}
