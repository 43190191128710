import { MutationTree } from 'vuex';
import _sumBy from 'lodash.sumby';
import { UserItem } from '~/types/user-items';

import {
  IInventoryState,
  IWallet,
  IClaimFee,
  IUpdateUserItemData,
  ITicketCollection,
  IExchangeReward,
  ITokenFeeVestingItem,
} from './types';

const bridgingBannedTokenNames: string[] = [];

export const mutations: MutationTree<IInventoryState> = {
  setWalletState(
    inventoryState: IInventoryState,
    { success, wallets }: { success: boolean; wallets: IWallet[] },
  ) {
    inventoryState.walletsErrored = !success;

    if (!success || !Array.isArray(wallets)) {
      inventoryState.wallets = [];
      return;
    }

    inventoryState.wallets = wallets.map((wallet, index) => {
      const currentWalletInState = inventoryState.wallets.find(
        stateWallet => stateWallet.symbol === wallet.symbol,
      );
      wallet.coinPrice =
        (currentWalletInState && currentWalletInState.coinPrice) || 0;

      return wallet;
    });
  },

  setWallet(inventoryState: IInventoryState, payload: IWallet) {
    if (!inventoryState.isFetchingWallets) {
      const indexToUpdate = inventoryState.wallets.findIndex(
        wallet => wallet.symbol === payload.symbol,
      );
      if (indexToUpdate >= 0) {
        const walletsArrCopy = [...inventoryState.wallets];
        walletsArrCopy.splice(indexToUpdate, 1, payload);
        inventoryState.wallets = walletsArrCopy;
      }
    }
  },

  updateIsFetchingWallets(inventoryState: IInventoryState, payload: boolean) {
    inventoryState.isFetchingWallets = payload;
  },

  updateWalletCoinPrices(
    inventoryState: IInventoryState,
    payload: { [key: string]: { USD: number } },
  ) {
    const { wallets } = inventoryState;

    const updatedWallets = wallets.map((wallet: IWallet) => {
      if (payload[wallet.symbol]) {
        return { ...wallet, coinPrice: payload[wallet.symbol]['USD'] };
      }
      return wallet;
    });
    inventoryState.wallets = updatedWallets;
  },

  updateUserItem(
    inventoryState: IInventoryState,
    payload: IUpdateUserItemData,
  ) {
    const { userItems } = inventoryState;
    const itemIndex = userItems.findIndex(
      i => i.uniqueInventoryPath === payload.itemUniqueInventoryPath,
    );
    const item = userItems[itemIndex];

    if (!item) {
      return;
    }

    if (payload.action === 'send' || payload.action === 'exchange') {
      const newQuantity = item.quantity - (payload.quantity || 0);

      if (newQuantity <= 0) {
        // Remove item from state
        inventoryState.userItems = userItems.filter(i => i !== item);
      } else {
        // Create a new item object with the updated quantity
        const newItem = { ...item };
        if (newItem.fungible) {
          newItem.quantity = newQuantity;
        } else {
          if (newItem.quantity !== 1) {
            throw new Error(
              `Expected new quantity for NFT ${newItem.uniqueInventoryPath} to be either 0 or 1. Got ${newItem.quantity}`,
            );
          }
          newItem.quantity = newItem.quantity;
        }

        // Insert new item object into the right place in new array
        inventoryState.userItems = [
          ...inventoryState.userItems.slice(0, itemIndex),
          newItem,
          ...inventoryState.userItems.slice(itemIndex + 1),
        ];
      }
    }
  },

  updateUserTickets(
    inventoryState: IInventoryState,
    userTickets: ITicketCollection[],
  ) {
    inventoryState.userTickets = userTickets ? [...userTickets] : [];
    inventoryState.userTickets.sort(
      (ticketA, ticketB) => ticketB.startDate - ticketA.startDate,
    );
  },

  updateUserItems(
    inventoryState: IInventoryState,
    { success, userItems }: { success: boolean; userItems: UserItem[] },
  ) {
    inventoryState.userItemsErrored = !success;

    if (!success) {
      inventoryState.userItems = [];
      return;
    }

    function getCanBridgeTo(item: UserItem) {
      if (!item.canBridgeTo || item.canBridgeTo.length === 0) {
        return item.canBridgeTo;
      }

      for (const bannedName of bridgingBannedTokenNames) {
        if (item.name.includes(bannedName)) {
          return [];
        }
      }

      return item.canBridgeTo;
    }

    function getUniqueInventoryPath(item: UserItem) {
      if (item.network === 'ETHEREUM' && item.fungible) {
        return `eth_ft_${item.ethereumContractAddress}_${item.ethereumBaseId}`.toLowerCase();
      }

      if (
        item.network === 'ETHEREUM' &&
        item.ethereumTokenStandard === 'erc1155'
      ) {
        return `eth_nft_${item.ethereumContractAddress}_${item.ethereumFullId}`.toLowerCase();
      }

      if (
        item.network === 'ETHEREUM' &&
        item.ethereumTokenStandard === 'erc721'
      ) {
        return `eth_nft_${item.ethereumContractAddress}_${item.nonFungibleInstanceId}`.toLowerCase();
      }

      if (item.network === 'ETH_TREASURE_CHEST') {
        return `tc_${item.treasureChestExportDetails[0].contractAddress}_${item.ethereumBaseId}`.toLowerCase();
      }

      if (item.network === 'GYRI') {
        return `gyri_${item.gyriTokenClassKey.collection}_${item.gyriTokenClassKey.category}_${item.gyriTokenClassKey.type}_${item.gyriTokenClassKey.additionalKey}_${item.nonFungibleInstanceId}`.toLowerCase();
      }

      if (item.network === 'GALACHAIN_ALLOWANCE') {
        return `galachain_allowance_${item.allowanceType}_${item.gyriTokenClassKey.collection}_${item.gyriTokenClassKey.category}_${item.gyriTokenClassKey.type}_${item.gyriTokenClassKey.additionalKey}`.toLowerCase();
      }

      if (item.network === 'OFF_CHAIN_TOKEN') {
        return `oc_${item.nonFungibleInstanceId}`;
      }

      console.warn(`Could not calculate a unique inventory path for`, item);

      return '';
    }

    function getFullName(item: UserItem) {
      if (item.fungible) {
        return item.name;
      }

      // Vox already have the instance ID in their
      if (/#[0-9]+$/.test(item.name)) {
        return item.name;
      }

      return `${item.name} #${item.nonFungibleInstanceId}`;
    }

    function getSendId(item: UserItem) {
      if (item.network === 'GYRI') {
        const classKey = item.gyriTokenClassKey;
        return `${classKey.collection}|${classKey.category}|${classKey.type}|${classKey.additionalKey}`;
      }

      if (item.network === 'ETH_TREASURE_CHEST') {
        return '';
      }

      if (item.network === 'ETHEREUM') {
        if (item.ethereumTokenStandard === 'erc1155') {
          return item.ethereumFullId;
        }

        if (item.ethereumTokenStandard === 'erc721') {
          return item.nonFungibleInstanceId;
        }
      }

      return '';
    }

    function getIsExchangeable(item: UserItem) {
      if (item.network === 'GYRI') {
        return item.gyriExchanges?.length > 0;
      }

      return item.isExchangeable;
    }

    // Augment the items from the API with some additional computed information
    inventoryState.userItems = userItems.map((item: UserItem) => ({
      ...item,
      uniqueInventoryPath: getUniqueInventoryPath(item),
      fullName: getFullName(item),
      sendId: getSendId(item),
      canBridgeTo: getCanBridgeTo(item) as any,
      isExchangeable: getIsExchangeable(item),
    }));
  },

  updateClaimFees(inventoryState: IInventoryState, payload: IClaimFee[]) {
    if (payload) {
      inventoryState.claimFees = payload;
    }
  },

  setGyriExchangeRewards(
    inventoryState: IInventoryState,
    rewards: IExchangeReward[],
  ) {
    inventoryState.mostRecentExchangeRewards = rewards;
  },

  incrementWalletBalance(
    inventoryState: IInventoryState,
    { symbol, amount }: { symbol: string; amount: string },
  ) {
    try {
      const coercedSymbol =
        /\[(?:ETH|GYRI)\]$/.test(symbol) || symbol === 'ETH'
          ? symbol
          : `${symbol}[ETH]`;

      const walletIndex = inventoryState.wallets.findIndex(
        w => w.symbol === coercedSymbol,
      );

      if (walletIndex === -1) {
        console.warn(`Could not find wallet with symbol ${symbol}`);
        return;
      }

      const wallet = inventoryState.wallets[walletIndex];

      // Stringified with no more than 8 decimal places
      const newConfirmed = Number.parseFloat(
        Math.max(Number(wallet.balance.confirmed) + Number(amount), 0).toFixed(
          8,
        ),
      ).toString();

      const newUnconfirmed = Number.parseFloat(
        Math.max(
          Number(wallet.balance.unconfirmed) + Number(amount),
          0,
        ).toFixed(8),
      ).toString();

      inventoryState.wallets = [
        ...inventoryState.wallets.slice(0, walletIndex),
        {
          ...wallet,
          balance: {
            ...wallet.balance,
            confirmed: newConfirmed,
            unconfirmed: newUnconfirmed,
          },
        },
        ...inventoryState.wallets.slice(walletIndex + 1),
      ];
    } catch (err) {
      console.warn(err);
    }
  },

  updateFeeVestingSchedule(
    inventoryState: IInventoryState,
    payload: {
      symbol: string;
      schedule: ITokenFeeVestingItem[];
    },
  ) {
    if (payload) {
      const { vestingScheduleByTokenId } = inventoryState;

      inventoryState.vestingScheduleByTokenId = {
        ...vestingScheduleByTokenId,
        [payload.symbol]: payload.schedule,
      };
    }
  },

  updateTokenRedistributionPool(
    inventoryState: IInventoryState,
    payload: {
      symbol: string;
      mintAbstentionPool: number;
    },
  ) {
    if (payload) {
      const { tokenRedistributionPoolAmount } = inventoryState;

      inventoryState.tokenRedistributionPoolAmount = {
        ...tokenRedistributionPoolAmount,
        [payload.symbol]: payload.mintAbstentionPool,
      };
    }
  },
};
