import { render, staticRenderFns } from "./FeeVestingRedistribution.vue?vue&type=template&id=424ca8ba&scoped=true&"
import script from "./FeeVestingRedistribution.vue?vue&type=script&lang=ts&"
export * from "./FeeVestingRedistribution.vue?vue&type=script&lang=ts&"
import style0 from "./FeeVestingRedistribution.vue?vue&type=style&index=0&id=424ca8ba&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424ca8ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
