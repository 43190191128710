







































import { Component, Vue, Prop } from 'vue-property-decorator';
import escapeHtml from 'escape-html';
import numeral from 'numeral';
import Color from 'color';

@Component
export default class FeeVestingRedistributionCard extends Vue {
  @Prop(String) readonly symbol!: string;
  @Prop(String) readonly tokenColor!: string;
  @Prop(String) readonly icon!: string;
  @Prop({ type: Number, default: 0 }) readonly redistributionAmount!: number;

  get darkenedColor() {
    return Color(`rgb(${this.tokenColor})`)
      .darken(0.5)
      .rotate(-15)
      .rgb();
  }

  get cardStyles() {
    const rgbaString = this.darkenedColor.alpha(0.05).string();

    return this.tokenColor
      ? {
          background: rgbaString,
        }
      : '';
  }

  get gradientStyles() {
    const gradient1 = `radial-gradient(circle 122px at 35% -40px, ${this.darkenedColor.string()} 65%, ${this.darkenedColor
      .alpha(0)
      .string()} 100%)`;
    const gradient2 = `radial-gradient(circle 284px at 105% -139px, rgba(${this.tokenColor}, 1) 54%, rgba(${this.tokenColor}, 0) 100%)`;

    return this.tokenColor
      ? {
          background: `${gradient1}, ${gradient2}`,
        }
      : '';
  }

  get formattedRedistributionAmount() {
    const wholeNumber = Number.isInteger(this.redistributionAmount);
    let formatString = '0,0[.][000]';

    if (this.redistributionAmount >= 100_000_000_000) {
      formatString = '0[.][00000000000]a';
    } else if (wholeNumber) {
      formatString = '0,0';
    } else {
      const amountStr = this.redistributionAmount.toString();
      const [whole] = amountStr.split('.');
      const allowedDecimals = 15 - whole.length;
      const decimalArray = Array(allowedDecimals)
        .fill(0)
        .join('');
      formatString = `0,0[.][${decimalArray}]`;
    }
    return this.$numeral(this.redistributionAmount, formatString);
  }

  get showTooltip() {
    return (
      numeral(this.formattedRedistributionAmount).value() !==
      numeral(this.redistributionAmount).value()
    );
  }

  get titleHtmlString() {
    return this.$t(
      'components.wallet.feeVesting.feeVestingRedistribution.title',
      {
        symbol: escapeHtml(this.symbol),
        color: escapeHtml(`rgb(${this.tokenColor})`),
      },
    );
  }

  get amountFontSize() {
    const elevenPlusCharacters = this.formattedRedistributionAmount.length > 10;

    if (this.$vuetify.breakpoint.smAndDown) {
      return elevenPlusCharacters ? '20px' : '28px';
    }

    return elevenPlusCharacters ? '24px' : '32px';
  }
}
