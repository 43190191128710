















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PromoSweepstakes extends Vue {
  @Prop() iframeUrl!: string;

  sessionStorageKey = 'gala-sweepstakes-seen';
  visible = false;
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  active = process.env.sweepstakesActive;

  async mounted() {
    if (this.iframeUrl) {
      try {
        const dateSeen = sessionStorage.getItem(this.sessionStorageKey);
        if (dateSeen) {
          const now = new Date();
          const nowDay = now.getDay();
          const seen = new Date(dateSeen);
          if (nowDay === 2 || nowDay === 3) {
            if (now.getTime() - seen.getTime() > 1000 * 60 * 60 * 24) {
              return this.showPopup();
            }
          }
        } else {
          return this.showPopup();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  showPopup() {
    setTimeout(() => {
      this.visible = true;
    }, 1000);
  }

  close() {
    this.visible = false;
    sessionStorage.setItem(this.sessionStorageKey, new Date().toISOString());
  }
}
