






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { BigNumber } from 'bignumber.js';
import { ITokenFeeVestingItem } from '~/store/inventory/types';
import { isTodayUTC } from '~/helpers/date-helpers';

@Component
export default class FeeVestingDisclaimer extends Vue {
  @Prop(String) readonly symbol!: string;
  @Prop(String) readonly tokenColor!: string;
  @Prop(Number) readonly amountToMint!: number;
  @Prop({ type: Array, default: () => [] })
  readonly schedule!: ITokenFeeVestingItem[];

  get lowRate() {
    const lowest = this.schedule[this.schedule.length - 1];
    return {
      ...lowest,
      date: format(new Date(lowest.date), 'MMM dd, y'),
    };
  }

  get currentRate() {
    const current = this.schedule.find(r => isTodayUTC(new Date(r.date)));
    return current?.burnToMintRatio || 0;
  }

  get potentialSavings() {
    const lowestFee = BigNumber(this.amountToMint).times(
      this.lowRate.burnToMintRatio,
    );
    const currentFee = BigNumber(this.amountToMint).times(this.currentRate);
    return currentFee.minus(lowestFee).toNumber();
  }

  get cardStyles() {
    return {
      background: `rgb(${this.tokenColor}, 0.05)`,
      border: `1px solid rgb(${this.tokenColor}, 0.2)`,
    };
  }
}
