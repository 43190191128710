














import { Component, Vue, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { getPercFormat } from '~/utils/format';

@Component
export default class FeeVestingGraph extends Vue {
  @Prop({ type: Array, default: [] }) readonly vestingData!: Array<{
    date: string;
    burnToMintRatio: number;
  }>;
  @Prop(String) readonly tokenColor!: string;

  tooltipLinePlugin = {
    id: 'tooltipLine',
    afterDraw: (chart: any) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        const activePoint = chart.tooltip._active[0].element;
        const x = activePoint.x;
        const topY = activePoint.y;
        const bottomY = chart.scales.y.bottom;

        // Check if the point is hovered
        if (activePoint.options.radius > 0) {
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(255, 255, 255, 0.75)';
          ctx.stroke();
          ctx.restore();
        }
      }
    },
  };

  get chartId() {
    return `vesting-schedule-chart-${uuidv4()}`;
  }

  get chartOptions() {
    return {
      plugins: {
        legend: {
          display: false, // Disable the legend
        },
        filler: {
          propagate: false,
        },
        title: {
          display: false,
          text: (ctx: any) => 'Vesting Schedule',
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          intersect: false,
          displayColors: false,
          callbacks: {
            // title: () => '', // Remove the title to only show the y-axis value
            label: (tooltipItem: any) => {
              const value = tooltipItem.raw;
              return getPercFormat(value, 0, false);
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(255,255,255, 0.1)', // Custom color for x-axis grid lines
            tickLength: 10, // Adjust the value as needed
          },
          ticks: {
            maxTicksLimit: 5, // Reduce the number of vertical grid lines
            align: 'center', // Align labels horizontally
            rotation: 0, // Set rotation to 0 degrees
            color: 'rgba(255,255,255, 0.6)', // Set tick label text color to white
          },
        },
        y: {
          grid: {
            color: 'rgba(255,255,255, 0.1)', // Custom color for y-axis grid lines
          },
          ticks: {
            callback: (value: string) => {
              return value + '%'; // Convert y-axis values to percentages
            },
            maxTicksLimit: 9, // Reduce the number of horizontal grid lines
            color: 'rgba(255,255,255, 0.6)', // Set tick label text color to white
          },
        },
      },
      elements: {
        point: {
          backgroundColor: 'transparent', // Set point background color to transparent
          borderColor: 'transparent', // Set border color to transparent
          borderWidth: 0, // Set border width
          radius: 0, // Hide points by default
          hoverRadius: 8, // Show points on hover
          hoverBorderWidth: 6, // Set hover border width
          hoverBackgroundColor: '#fff', // Ensure hover background color is white
          hoverBorderColor: '#000', // Ensure hover border color is black
        },
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'x',
      },
      animation: {
        duration: 0, // Disable animation for point radius
      },
      hover: {
        animationDuration: 0, // Disable hover animation
      },
    };
  }

  get chartData() {
    return {
      labels:
        this.vestingData?.map?.((item: any) => {
          const localDate = new Date(item.date);
          return format(
            new Date(
              localDate.getUTCFullYear(),
              localDate.getUTCMonth(),
              localDate.getUTCDate(),
            ),
            'MMM d',
          );
        }) || [],
      datasets: [
        {
          data:
            this.vestingData?.map?.(
              (item: any) => item.burnToMintRatio * 100,
            ) || [],
          borderColor: 'transparent',
          backgroundColor: (context: any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }

            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom,
            );
            gradient.addColorStop(0, `rgb(${this.tokenColor}, 0.8)`); // Less transparent at the top
            gradient.addColorStop(1, `rgb(${this.tokenColor}, 0.01)`); // More transparent at the bottom

            return gradient;
          },
          fill: true,
          pointBackgroundColor: '#fff', // Ensure point background color is white
          pointBorderColor: '#000', // Ensure point border color is black
          pointHoverBackgroundColor: '#fff', // Ensure hover background color is white
          pointHoverBorderColor: '#000', // Ensure hover border color is black
        },
      ],
    };
  }
}
